function TableRowsAge({rowsData, deleteTableRows, handletableChange}) {
    return(
        rowsData.map((data, index)=>{
            const {age,no_of_moulds,id}=data
            return(

                    <tr  key={index} className="text-center detailclass" id="after-this1">
                        <td><button className="btn btn-danger" onClick={()=>(deleteTableRows(index))}>x</button></td>
                        <td><input type="number" min="0" max="28" name="age" value={age} onChange={(evnt)=>(handletableChange(index, evnt))} style={{width:"100px"}} className="form-control  mandatory-form-control"  /></td>
                        <td><input type="number" min="0" max="12" style={{width: "100px"}} className="form-control  mandatory-form-control" value={no_of_moulds} onChange={(evnt)=>(handletableChange(index, evnt))}  name="no_of_moulds" required /></td>
                        <td><input type="text" max="10" style={{width: "100px"}} className="form-control" value={id} onChange={(evnt)=>(handletableChange(index, evnt))}  name="id"/></td>
                    </tr>
                )
        })
    )
}
export default TableRowsAge