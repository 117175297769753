import Swal from "sweetalert2";
import { getHTMLForSummaryPopup } from '../../utils/PopupUtils';
import {parseBooleanToString} from '../../helpers';

  export function showDcGroupPop(item){
    Swal.fire({
      html:getHTMLForSummaryPopup(
          'DC GROUP DETAILS',
          [
          {label: 'BILLING NAME', value: item.customer_name},
          {label: 'PROJECT/SITE', value: item.project_name},
          {label: 'DC DATE', value: item.delivery_challan_date},
          {label: 'GRADE', value: item.product_name},
          {label: 'TOTAL DC QTY', value: item.total_dc_qty}
          ])
  }, '', 'info');
  }
