function TableRowsCubeTestReportEdit({csrRows,handleChangeCsr}) {
    const groupedByAge = csrRows.reduce((acc, row, index) => {
        if (!acc[row.age]) {
            acc[row.age] = [];
        }
        acc[row.age].push({ ...row, index });
        return acc;
    }, {});
    console.log(JSON.stringify(csrRows)+"csrRows edit table");
    return(
        csrRows.map((data, index)=>{
            const {id,sl_no,age,sample_id,no_of_rows,age_date,act_weight,act_load,act_strength,act_average,act_difference,act_result,
                cus_weight,cus_load,cus_strength,cus_average,cus_difference,cus_result,user_remarks,cus_strength_result,act_strength_result,ref_cube_strength}=data
            // Check if this row is the first in its group
            const isFirstRowInGroup = groupedByAge[age][0]?.index === index;
            // console.log(isFirstRowInGroup+"isFirstRowInGroup");
            const rowspan = isFirstRowInGroup ? groupedByAge[age].length : 0;
            return(
                <tr key={index}>
                    <td style={{display:"None"}}>{id}</td>
                    <td  style={{display:"None"}}>{no_of_rows}</td>
                    <td>
                        <input type="number"  style={{width:"80px",backgroundColor:"white", cursor: "not-allowed"}} readOnly={true} size="10" id="sl_no" value={sl_no} className="form-control"  name="sl_no"/>
                    </td>
                    <td>
                        <input type="text"  style={{width:"80px",backgroundColor:"white", cursor: "not-allowed"}}  readOnly={true} className="form-control " id="sample_id" name="sample_id"   value={sample_id} />
                    </td>
                    <td>
                        <input type="number" style={{width:"80px",backgroundColor:"white", cursor: "not-allowed"}}  readOnly={true} id="age" value={age} className="form-control"  size="10" name="age"/>
                    </td>
                    <td>
                        <input type="date"  style={{width:"180px",backgroundColor:"white", cursor: "not-allowed"}}  readOnly={true} className="form-control"  id="age_date" name="age_date"  value={age_date} />
                    </td>
                    <td>
                        <input type="number" step="any"  style={{width: "100px",textAlign:"right"}} className="form-control" id="act_weight"  onChange={(evnt)=>(handleChangeCsr(index, evnt))} value={act_weight} required name="act_weight" min="0"/></td>
                    <td>
                        150*150
                    </td>  
                    <td>
                        22500
                    </td>  
                    {isFirstRowInGroup && (
                    <td style={{verticalAlign:"middle",fontWeight:"bold"}} rowSpan={rowspan} >
                        {ref_cube_strength}
                    </td> )} 
                    <td>
                        <input type="number" step="any" style={{width: "100px",textAlign:"right"}} class="form-control" id="act_load"   required name="act_load" onChange={(evnt)=>(handleChangeCsr(index, evnt))} value={act_load} min="0"/>
                    </td>
                    <td>
                        <input type="text"   style={{width: "80px",textAlign:"right", backgroundColor: "white", cursor: "not-allowed",color:act_strength_result==='FAIL'?'red':'green',fontWeight:"bold"}} className="form-control" id="act_strength"  readonly name="act_strength" value={act_strength} min="0"/>
                    </td>  
                    {/* Conditionally render the "average" cell */}
                    {isFirstRowInGroup && (
                        <td style={{verticalAlign:"middle",color:act_result==='FAIL'?'red':'green',fontWeight:"bold"}} rowSpan={rowspan} >
                            {(groupedByAge[age].reduce((total, row) => total + parseFloat(row.act_strength || 0), 0) / groupedByAge[age].length).toFixed(2)} / {act_result}
                        </td>
                    )}
                    <td style={{display:"None"}}>
                        <input type="text" style={{width: "80px",textAlign:"right", backgroundColor: "white", cursor: "not-allowed"}}className="form-control" id="act_difference"  readonly name="act_difference" value={act_difference}/>
                    </td>   
                    <td>
                        <input type="number" step="any"  style={{width: "100px",textAlign:"right"}} className="form-control" id="cus_weight" value={cus_weight} min="0" required  name="cus_weight" onChange={(evnt)=>(handleChangeCsr(index, evnt))}/>
                    </td>  
                    <td>
                        <input type="number" step="any" style={{width: "100px",textAlign:"right"}} className="form-control" id="cus_load" required min="0" name="cus_load" value={cus_load} onChange={(evnt)=>(handleChangeCsr(index, evnt))}/>
                    </td>
                    <td>
                        <input type="text"  style={{width: "80px",textAlign:"right", backgroundColor: "white", cursor: "not-allowed",color:cus_strength_result==='FAIL'?'red':'green',fontWeight:"bold"}} className="form-control" value={cus_strength} id="cus_strength" min="0" readonly name="cus_strength"/>
                    </td>  
                    {/* Conditionally render the "average" cell */}
                    {isFirstRowInGroup && (
                        <td style={{verticalAlign:"middle",color:cus_result==='FAIL'?'red':'green',fontWeight:"bold"}} rowSpan={rowspan} >
                            {(groupedByAge[age].reduce((total, row) => total + parseFloat(row.cus_strength || 0), 0) / groupedByAge[age].length).toFixed(2)} / {cus_result}
                        </td>
                    )}
                    <td style={{display:"None"}}>
                        <input type="text"  value={cus_difference}  style={{width: "80px",textAlign:"right", backgroundColor: "white", cursor: "not-allowed"}}className="form-control" id="cus_difference"  readonly name="cus_difference" min="0"/>
                    </td>   
                    <td>
                        <input type="text" onChange={(evnt)=>(handleChangeCsr(index, evnt))} style={{width: "80px",textAlign:"right"}}className="form-control"  name="user_remarks" value={user_remarks}/>
                    </td>      
                </tr>
            )
        })
    )
}
export default TableRowsCubeTestReportEdit;