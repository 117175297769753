import { getApiBaseUrl,getApiClient } from "./serviceconfig";

var apiBaseUrl = getApiBaseUrl();
export async function getCubeTestReportNumber(cookies,plantId,csrDate) {
  console.log(plantId+"plantId"+csrDate+"csrDate")
  let url= `${apiBaseUrl}/cube_test_report/cube_test_report_number/`;
  if(csrDate && plantId){
    url = url +  `?plant_id=${plantId}&cube_test_report_date=${csrDate}`;
  }
  else{
    url = url +  `?plant_id=${plantId}`;
  }
  return getApiClient(cookies).get(url)
  .then(response => response.data);
}

export async function createCubeTestReport(cookies, inputs) {
  return getApiClient(cookies).post(`${apiBaseUrl}/cube_test_report/`, inputs)
}

export async function getAllCubeTestReports(cookies,inputs) {
  let url= `${apiBaseUrl}/cube_test_report/?plant_id=${inputs.plant_id}&from_date=${inputs.from_date}&upto_date=${inputs.upto_date}`;
    return getApiClient(cookies).get(url)
    .then(response => response.data);
}

export async function printCubeTestReport(cookies, ctrId) {
    return getApiClient(cookies).get(`${apiBaseUrl}/cube_test_report/${ctrId}/print_cube_test_report/`)
    .then(response => response.data)
}

export async function getCubeTestReportDetails(cookies, ctrId) {
  return getApiClient(cookies)
  .get(`${apiBaseUrl}/cube_test_report/${ctrId}/`)
  .then(response => response.data);
}

export function deleteCubeTestReport(cookies, ctrId) {
  return getApiClient(cookies).delete(`${apiBaseUrl}/cube_test_report/${ctrId}/`); 
}

export function cancelCubeTestReport(cookies, ctrId) {
  return getApiClient(cookies).patch(`${apiBaseUrl}/cube_test_report/${ctrId}/`)
}

export function updateCubeTestReport(cookies, inputs, ctrId) {
  return getApiClient(cookies).put(`${apiBaseUrl}/cube_test_report/${ctrId}/`, inputs)
}



 